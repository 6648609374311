/* Upload Photo Form Container */
.upload-photo-form {
    max-width: 600px;
    margin: 30px auto;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  /* Form Title */
  .form-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #4CAF50;
  }
  
  /* Form Group */
  .form-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  
  /* Form Label */
  .form-label {
    margin-bottom: 5px;
    font-size: 16px;
    color: #333;
  }
  
  /* Form Input */
  .form-input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .form-input:focus {
    border-color: #4CAF50;
    box-shadow: 0 0 8px rgba(76, 175, 80, 0.2);
  }
  
  /* Submit Button */
  .submit-button {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 12px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .submit-button:hover {
    background-color: #45A049;
    transform: translateY(-2px);
  }
  
  .submit-button:active {
    background-color: #388E3C;
  }
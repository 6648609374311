/* src/components/CarDetails.css */

.car-details-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Full viewport height */
  text-align: center;
  margin-top: -60px;
  
}

.page-title {
  color: #333;
  margin-bottom: 20px;
}

.car-details {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.car-image {
  width: 600px;
  height: 400px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.shuffle-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;
}

.shuffle-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
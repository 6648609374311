/* Photos Container */
.photos-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

/* Page Title */
.page-title {
  font-size: 32px;
  margin-bottom: 20px;
  color: #4CAF50;
}

/* Photo Gallery Grid */
.photo-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Responsive grid layout */
  gap: 10px;
}

/* Individual Photo Item */
.photo-item {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Photo Image Styling */
.photo-image {
  width: 100%;
  max-width: 200px; /* Fixed width for the images */
  height: auto;
  margin-bottom: 10px;
  border-radius: 5px;
}

/* Photo Description */
.photo-description {
  font-size: 14px;
  color: #555;
}

/* Responsive Design */
@media (max-width: 600px) {
  .photo-gallery {
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); /* Smaller grid for mobile devices */
    gap: 8px;
  }

  .photo-item {
    padding: 8px;
  }

  .photo-image {
    max-width: 120px; /* Smaller image size for mobile */
  }

  .photo-description {
    font-size: 12px;
  }
}